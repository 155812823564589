import React from "react";

function ItemCarousel(props) {
  var className = props.active ? "carousel-item active" : "carousel-item";
  return (
    <div className={className}>
      <img src={props.src} className="d-block w-100" alt={props.title} />
    </div>
  );
}

export default ItemCarousel;
