import React from "react";

function ItemLicense(props) {
  return (
    <div className="col-10 col-md-6 col-lg-3 d-flex align-items-stretch mb-4">
      <div className="icon-box">

      {props.img != null && props.img.length ? <img alt="" src={props.img} className="img-icon" /> : <div className="icon">
          <i className="bx bx-award"></i>
        </div>}
        
        <h4 className="title">
          <p>{props.title}</p>
        </h4>
        <p className="description">{props.description}</p>
        {props.description2?<p className="description font-weight-bold mt-2">{props.description2}</p>:null }
      </div>
    </div>
  );
}

export default ItemLicense;
