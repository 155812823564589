import Styles from "./login.module.css";
import { useState, useRef, useEffect } from "react";

const Login = (props) => {
  const [loading, setLoading] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const handleLogin = (event) => {
    const formData = new FormData(event.currentTarget);
    event.preventDefault();

    setLoading(true);
    fetch("https://esmasgas.com.mx/api/ApiSesion/Sesion", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(Object.fromEntries(formData)),
    })
      .then((response) => response.json())
      .then((response) => {
        setLoading(false);
        if (!response.error) {
          const json = JSON.parse(response.objeto);

          window.location = json.url;
        } else {
          var toastLiveExample = document.getElementById("liveToast");
          // eslint-disable-next-line no-undef
          var toast = new bootstrap.Toast(toastLiveExample);

          setMessageError(response.mensaje);
          toast.show();
        }
      });
  };

  const embedRef = useRef(null);

  useEffect(() => {
    const embedElement = embedRef.current;
    if (embedElement) {
      const observer = new MutationObserver(mutations => {
        mutations.forEach(mutation => {
          if (mutation.attributeName === 'src') {
            const newSrc = embedElement.getAttribute('src');
            if (newSrc) {
              console.log('El atributo src cambió:', newSrc);
            }
          }
        });
      });

      observer.observe(embedElement, { attributes: true });

      return () => {
        observer.disconnect();
      };
    }
  }, []);

  return (
    <section class={Styles["main"]} id="section-main">
      <div class="container py-5 h-100">
        <div class="row d-flex justify-content-center align-items-center h-100">
          <div class="col col-xl-10">
            <div class="card">
              <div class="row g-0">
                <div
                  class={`col-md-6 col-lg-5 d-none d-md-block ${Styles["left-container-login"]}`}
                ></div>
                <div
                  class={`col-md-6 col-lg-7 d-flex align-items-center ${Styles["right-container-login"]}`}
                >
                  <div class="card-body p-4 p-lg-5 text-black">
                    <embed
                      ref={embedRef}
                      src="http://localhost:8082/Configuracion/Usuarios/Cognito/vista/login.aspx?client_id=6l6elgpre1onv7um0uiqnifkol&redirect_uri=www.google.com"
                    ></embed>
                    {/* <form onSubmit={handleLogin}>
                      <div class="text-center mb-3 pb-1">
                        <img
                          src="/static/media/logo_fondo_primario.f99c98b45a448ad6b6ba1e1a8da93543.svg"
                          alt="Nova Tecnología Administrativa, S.A de C.V"
                          class={Styles["logo"]}
                        />
                      </div>

                      <h5 class="fw-normal text-center mb-3 pb-3">
                        Inicio de sesión
                      </h5>

                      <div className="row mb-4">
                        <div className="col-12 form-group">
                          <input
                            type="text"
                            name="usuario"
                            className="form-control"
                            placeholder="Usuario"
                            required
                          />
                        </div>
                      </div>
                      <div className="row mb-4">
                        <div className="col-12 form-group">
                          <input
                            type="password"
                            name="contrasena"
                            className="form-control"
                            placeholder="Contraseña"
                            required
                          />
                          <input
                            type="number"
                            name="tipo"
                            hidden="hidden"
                            value={1}
                            required
                          />
                        </div>
                      </div>
                      <div class="pt-1 mb-4 d-grid">
                        <button type="submit" class="btn btn-lg btn-block">
                          {loading ? (
                            <>
                              <span
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            </>
                          ) : (
                            <>Iniciar</>
                          )}
                        </button>
                      </div>
                    </form> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="position-fixed bottom-0 end-0 p-3">
        <div
          id="liveToast"
          class="toast"
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
        >
          <div class="toast-header">
            <strong class="me-auto">Notificación</strong>
            <small>Ahora</small>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="toast"
              aria-label="Close"
            ></button>
          </div>
          <div class="toast-body">{messageError}</div>
        </div>
      </div>
    </section>
  );
};

export default Login;
