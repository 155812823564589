import "../assets/styles/App.css";
import "../assets/font/stylesheet.css";
import DataIndex from "./DataIndex";
import FloatingBtn from "./FloatingBtn";
import ItemCarousel from "./ItemCarousel";
import ItemLicense from "./ItemLicense";
import ItemList from "./ItemList";
import ItemPaquete from "./ItemPaquete";
import "./../assets/styles/App.css";

function App() {
  return (
    <div>
      <div className="carousel slide" data-bs-ride="carousel" id="hero">
        <div className="carousel-inner">
          {DataIndex.home.carousel.map((actual, index) => {
            return <ItemCarousel {...actual} key={index} />;
          })}
        </div>
        <div className="hero-container container" id="home">
          <div className="row">
            <div className="col-lg-5 text-white">
              <h3>{DataIndex.home.tagWelcome}</h3>
              <h1>{DataIndex.home.tagPhrase}</h1>
              <p>{DataIndex.home.tagDescription}</p>
              <a
                href={DataIndex.home.href}
                className="btn-get-started scrollto"
              >
                {DataIndex.home.tagStarted}
              </a>
            </div>
          </div>
        </div>
      </div>
      <main id="main">
        <section id="about" className="about">
          <div className="container">
            <div className="section-title">
              <h2>{DataIndex.about.title}</h2>
              <h3>
                <span>{DataIndex.global.nameComercial}</span>
              </h3>
              <p className="h5">{DataIndex.about.subtitle}</p>
            </div>

            <div className="row content py-3">
              <div className="col-lg-6">
                <p>{DataIndex.about.section1}</p>
                <ul>
                  {DataIndex.about.list.map((actual, index) => {
                    return <ItemList {...actual} key={index} />;
                  })}
                </ul>
              </div>
              <div className="col-lg-6 pt-4 pt-lg-0">
                <p className="text-justify">{DataIndex.about.section2}</p>
              </div>
            </div>
          </div>
        </section>

        <section id="cta0" className="cta">
          <div className="container">
            <div className="text-center">
              <h3>{DataIndex.action.title}</h3>
              <p> {DataIndex.action.description}</p>
              <a className="cta-btn" href="#contact">
                {DataIndex.action.tagContactanos}
              </a>
            </div>
          </div>
        </section>

        <section id="products" className="services">
          <div className="container">
            <div className="section-title">
              <h2>{DataIndex.products.title}</h2>
              <h3>
                {DataIndex.global.tagNuestros}{" "}
                <span>{DataIndex.products.title}</span>{" "}
                {DataIndex.products.subtitle}
              </h3>
              <p>{DataIndex.products.description}</p>
            </div>

            <div className="row justify-content-center">
              {DataIndex.products.list.map((actual, index) => {
                return <ItemLicense {...actual} key={index} />;
              })}
            </div>
          </div>
        </section>

        <section id="cta" className="cta">
          <div className="container">
            <div className="text-center">
              <h3>{DataIndex.action.title}</h3>
              <p> {DataIndex.action.description}</p>
              <a className="cta-btn" href="#contact">
                {DataIndex.action.tagContactanos}
              </a>
            </div>
          </div>
        </section>

        <section id="paquetes" className="packages">
          <div className="container">
            <div className="section-title">
              <h2>{DataIndex.paquetes.title}</h2>
              <h3>
                {DataIndex.global.tagNuestros}{" "}
                <span>{DataIndex.paquetes.title}</span>
              </h3>
              <p>{DataIndex.paquetes.description}</p>
            </div>

            <div className="text-center">
              <p>Todos los planes incluyen lo siguiente:</p>
              {DataIndex.paquetes.apps ? (
                <ul className="list-unstyled ul-img-icons-apps">
                  {DataIndex.paquetes.apps.map((o, index) => {
                    return (
                      <li className="item-app" key={index}>
                        <img src={o.icon} />
                        <p>{o.name}</p>
                      </li>
                    );
                  })}
                </ul>
              ) : null}
            </div>

            <div className="row justify-content-center">
              {DataIndex.paquetes.list.map((actual, index) => {
                return <ItemPaquete {...actual} key={index} />;
              })}
            </div>
          </div>
        </section>
        <section id="cta3" className="cta">
          <div className="container">
            <div className="text-center">
              <h3>{DataIndex.action.title}</h3>
              <p> {DataIndex.action.description}</p>
              <a className="cta-btn" href="#contact">
                {DataIndex.action.tagContactanos}
              </a>
            </div>
          </div>
        </section>
        <section id="polizas" className="polizas">
          <div className="container">
            <div className="section-title">
              <h2>{DataIndex.polizas.title}</h2>
              <h3>
                {DataIndex.global.tagNuestros}{" "}
                <span>{DataIndex.polizas.title}</span>
              </h3>
              <p>{DataIndex.polizas.description}</p>
            </div>

            <div className="row justify-content-center">
              {DataIndex.polizas.list.map((actual, index) => {
                return <ItemPaquete {...actual} key={index} />;
              })}
            </div>
          </div>
        </section>

        <section id="contact" className="contact">
          <div className="container">
            <div className="section-title">
              <h2>{DataIndex.contact.title}</h2>
              <h3>
                <span>{DataIndex.contact.subtitle}</span>
              </h3>
              <p>{DataIndex.contact.description}</p>
            </div>

            <div></div>

            <div className="row mt-5">
              <div className="col-lg-4">
                <div
                  className="nav flex-column nav-pills me-3 list-group list-group-flush"
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  <li
                    className="list-group-item active"
                    id="tab-contrata"
                    data-bs-toggle="pill"
                    data-bs-target="#tab-content-contrata"
                    role="tab"
                    aria-controls="tab-content-contrata"
                    aria-selected="true"
                  >
                    Contrata
                  </li>
                  <li
                    className="list-group-item"
                    id="tab-contacta"
                    data-bs-toggle="pill"
                    data-bs-target="#tab-content-contacta"
                    role="tab"
                    aria-controls="tab-content-contacta"
                    aria-selected="false"
                  >
                    Contacta
                  </li>
                </div>
              </div>

              <div className="col-lg-8 mt-5 mt-lg-0">
                <div className="tab-content" id="v-pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="tab-content-contrata"
                    role="tabpanel"
                    aria-labelledby="tab-contrata"
                  >
                    <form
                      action={DataIndex.global.mailto}
                      method="post"
                      name="form1"
                      className="php-email-form"
                      autocomplete="off"
                    >
                      <div className="row">
                        <div className="col-md-6 form-group">
                          <input
                            type="text"
                            name="name"
                            className="form-control"
                            id="name"
                            placeholder={DataIndex.contact.tagNombre}
                            required
                          />
                        </div>
                        <div className="col-md-6 form-group mt-3 mt-md-0">
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            id="email"
                            placeholder={DataIndex.footer.tagEmail}
                            required
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 form-group">
                          <input
                            type="text"
                            name="name_estacion"
                            className="form-control"
                            id="name_estacion"
                            placeholder={DataIndex.contact.tagEstacion}
                            required
                          />
                        </div>
                        <div className="col-md-6 form-group mt-3 mt-md-0">
                          <input
                            type="text"
                            className="form-control"
                            name="cre"
                            id="cre"
                            placeholder={DataIndex.contact.tagCRE}
                            required
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 form-group">
                          <input
                            type="text"
                            name="name_estacion"
                            className="form-control"
                            id="name_estacion"
                            placeholder={DataIndex.contact.tagCargo}
                            required
                          />
                        </div>
                        <div className="col-md-6 form-group mt-3 mt-md-0">
                          <input
                            type="text"
                            className="form-control"
                            name="cre"
                            id="cre"
                            placeholder={DataIndex.footer.tagPhone}
                            required
                          />
                        </div>
                      </div>
                      <div className="form-group mt-3">
                        <textarea
                          className="form-control"
                          name="message"
                          rows="5"
                          placeholder={DataIndex.contact.tagResumen}
                          required
                        ></textarea>
                      </div>
                      <div className="my-3">
                        <div className="loading">Loading</div>
                        <div className="error-message"></div>
                        <div className="sent-message">
                          Your message has been sent. Thank you!
                        </div>
                      </div>
                      <div className="text-center">
                        <button type="submit">
                          {DataIndex.global.tagEnviar}
                        </button>
                      </div>
                    </form>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="tab-content-contacta"
                    role="tabpanel"
                    aria-labelledby="tab-contacta"
                  >
                    <form
                      action={DataIndex.global.mailto}
                      method="post"
                      name="form1"
                      className="php-email-form"
                      autocomplete="off"
                    >
                      <div className="row">
                        <div className="col-md-6 form-group">
                          <input
                            type="text"
                            name="name"
                            className="form-control"
                            id="name"
                            placeholder={DataIndex.contact.tagNombre}
                            required
                          />
                        </div>
                        <div className="col-md-6 form-group mt-3 mt-md-0">
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            id="email"
                            placeholder={DataIndex.footer.tagEmail}
                            required
                          />
                        </div>
                      </div>
                      <div className="form-group mt-3">
                        <textarea
                          className="form-control"
                          name="message"
                          rows="5"
                          placeholder={DataIndex.contact.tagResumen}
                          required
                        ></textarea>
                      </div>
                      <div className="my-3">
                        <div className="loading">Loading</div>
                        <div className="error-message"></div>
                        <div className="sent-message">
                          Your message has been sent. Thank you!
                        </div>
                      </div>
                      <div className="text-center">
                        <button type="submit">
                          {DataIndex.global.tagEnviar}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <div className="view-floating-btn">
        {DataIndex.floatingBtn.map((actual, index) => {
          return <FloatingBtn {...actual} key={index} />;
        })}
      </div>
    </div>
  );
}

export default App;
