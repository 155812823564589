import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "../../components/App";
import Login from "../../pages/login/login";

const Main = (props) => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<App />} path="/"></Route>
        <Route path="/login" element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Main;
