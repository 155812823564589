import React from "react";

function ItemCarousel(props) {
  return (
    <li  className="item-olist">
      <i className="ri-check-fill"></i> {props.text}
    </li>
  );
}

export default ItemCarousel;
