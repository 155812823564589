import ReactDOM from "react-dom";
import "./assets/vendor/boxicons/css/boxicons.css";
import "./assets/vendor/remixicon/remixicon.css";
import "./assets/vendor/swiper/swiper-bundle.min.css";
import "./assets/font/stylesheet.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Main from "./routes/main/Main";

ReactDOM.render(
  <>
    <Header />
    <Main />
    <Footer />
  </>,
  document.body
);
