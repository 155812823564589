import React from "react";
import ItemList from "./ItemList";
function ItemPaquete(props) {
  return (
    <div className="col-10 col-lg-3 col-md-4 mb-4 d-grid">
      <div className="box bbox">
        <h3 className="title">{props.title}</h3>
        <p className="description">{props.description}</p>
        <div className="box-inside-container">
          <div className="pricing-container">
            <div className="d-none">
              <div className="pricing">
                <span className="currency-symbol-left">{props.simbolCurrency}</span>
                <span className="currency">{props.price}</span>
              </div>
              <p>{props.detailPrice}</p>
            </div>
            <a className="btn" href={ props.href }>{ props.buttonText? props.buttonText: "ELEGIR PLAN" }</a>
            <div className="separator"></div>
          </div>
          <div>
          
            
          
            {props.features != null && props.features.length > 0 ?

              <ul className="list-unstyled">
                {props.features.map((actual, index) => {
                  return <ItemList {...actual} key={index} />;
                })}
              </ul>
              : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ItemPaquete;
