import React from "react";

function ItemMenuFooter(props) {

  let aElement= <a href={props.href}>{props.title}</a>;

  if(props.newTab)
    aElement= <a href={props.href} target="_blank">{props.title}</a>;

  return (
    <li>
      <i className="bx bx-chevron-right"></i>{" "}
      {aElement}
    </li>
  );
}

export default ItemMenuFooter;
