import React from "react";
import "../assets/styles/Footer.css";
import "./DataIndex";
import DataIndex from "./DataIndex";
import ItemMenuFooter from "./ItemMenuFooter";

function Footer() {
  return (
    <div>
      <footer id="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6 footer-contact">
                <h3>{DataIndex.global.nameComercial}</h3>
                <p>
                  {DataIndex.global.address.street}
                  <br />
                  {DataIndex.global.address.suburb}
                  <br />
                  {DataIndex.global.address.city}
                  <br />
                  <br />
                  <strong>{DataIndex.footer.tagPhone}:</strong>{" "}
                  <a href={ "tel:+52"+ DataIndex.global.address.phone} target="_black"> {DataIndex.global.address.phone}</a>
                 
                  <br />
                  <strong>{DataIndex.footer.tagEmail}:</strong>{" "}
                   <a href={"mailto:"+ DataIndex.global.address.email}  target="_black">{DataIndex.global.address.email}</a>
                  <br />
                </p>
              </div>

              <div className="col-lg-2 col-md-6 footer-links">
                <h4>{DataIndex.footer.tagUseful}</h4>
                <ul>
                  {DataIndex.header.menu.map((actual, index) => {
                    return <ItemMenuFooter {...actual} key={index} />;
                  })}
                </ul>
              </div>

              <div className="col-lg-3 col-md-6 footer-links">
                <h4>{DataIndex.footer.tagOthers}</h4>
                <ul>
                  {DataIndex.footer.menu.map((actual, index) => {
                    return <ItemMenuFooter {...actual} key={index} />;
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="container d-md-flex py-4">
          <div className="me-md-auto text-center text-md-start">
            <div className="copyright">
              &copy; {DataIndex.footer.tagCopyright}{" "}
              <strong>
                <span>{DataIndex.global.nameCompany}</span>
              </strong>
              . {DataIndex.footer.tagRights}.
            </div>
          </div>
          <div className="social-links text-center text-md-right pt-3 pt-md-0 d-none">
            <a href="/" className="twitter">
              <i className="bx bxl-twitter"></i>
            </a>
            <a href="/" className="facebook">
              <i className="bx bxl-facebook"></i>
            </a>
            <a href="/" className="instagram">
              <i className="bx bxl-instagram"></i>
            </a>
            <a href="/" className="linkedin">
              <i className="bx bxl-linkedin"></i>
            </a>
          </div>
        </div>
      </footer>

      <a
        href="/"
        className="back-to-top d-flex align-items-center justify-content-center"
      >
        <i className="bi bi-arrow-up-short"></i>
      </a>
    </div>
  );
}

export default Footer;
