import React from "react";
import Scrollspy from "react-scrollspy";

export default class ItemMenuHeader extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Scrollspy
          items={[this.props.href.replace("#", "")]}
          currentClassName="active"
          componentTag="li"
        >
          <a className="nav-link" href={this.props.href}>{this.props.title}</a>
        </Scrollspy>
      </React.Fragment>
    );
  }
}
