import background1 from "../assets/images/fondo1.svg";
import background2 from "../assets/images/fondo2.svg";

import icon_sc_cv from "../assets/images/logo_sg_cv.svg";
import icon_sc_pos from "../assets/images/logo_sg_pos.svg";
import icon_sc from "../assets/images/logo_smartVol.svg";
import icon_sc_web from "../assets/images/logo_sg_web.svg";

const DataIndex = {
  global: {
    nameCompany: "Nova Tecnología Administrativa, S.A de C.V",
    nameComercial: "Novasyn",
    nameSuite: "Novasyn",
    mailto: "mailto:soporte.clientes@novasyn.mx",
    address: {
      street: "Prolongación 27 de Febrero 2801",
      suburb: "Colonia Atasta de Serra",
      city: "Villahermosa, Tabasco 86100 México",
      phone: "800 055 3666",
      email: "soporte.clientes@novasyn.mx",
    },
    tagNuestras: "Nuestras",
    tagNuestros: "Nuestros",
    tagEnviar: "Enviar correo",
  },
  header: {
    logo: "",
    menu: [
      { title: "Nosotros", href: "/#about", submenu: [] },
      { title: "Productos", href: "/#products", submenu: [] },
      { title: "Paquetes", href: "/#paquetes", submenu: [] },
      { title: "Contrata", href: "/#contact", submenu: [] },
      { title: "Contacto", href: "/#contact", submenu: [] },
      { title: "Iniciar", href: "https://www.consorciolemon.com.mx/Configuracion/Usuarios/Cognito/vista/login.aspx?client_id=u0tkti3jvdbh2p0jogfgqlg6l&redirect_uri=www.consorciolemon.com.mx&uri_css=https://www.consorciolemon.com.mx/Configuracion/Usuarios/Cognito/css/estilo4.css&uri_logo=https://novasyn.mx/favicon.ico", submenu: [] },
    ],
  },
  home: {
    tagWelcome: "SOLUCIONES TECNOLÓGICAS",
    tagPhrase: "PARA GASOLINERAS Y ESTACIONES DE SERVICIO",
    tagDescription:
      "Con nuestras aplicaciones podra llevar un mejor control y gestión de su estación de servicio. Monitorea inventarios, entradas, salidas , ventas y cumpla con las obligaciones de ley.",
    tagStarted: "Ver productos",
    href: "#products",
    carousel: [
      {
        src: background1,
        title: "Nova",
        active: true,
      },
      {
        src: background2,
        title: "Nova 2",
        active: false,
      },
    ],
  },
  get about() {
    return {
      title: "Sobre Nosotros",
      subtitle: `${this.global.nameComercial} es una empresa 100% mexicana enfocada a desarrollar soluciones informáticas para el mercado gasolinero, ofreciendote un abanico de soluciones para hacer más eficientes los procesos administrativos y operativos de su negocio. Todo esto bajo una suite escalable que se adapta a las necesidades de tu negocio, haciéndote una solución a tu medida.`,
      section1: `${this.global.nameSuite} es tecnología innovadora que necesita los negocios hoy en día para colocarse un paso delante de la competencia, brindando beneficios como:`,
      section2: `La suite ${this.global.nameSuite} es la síntesis de años de acreditada experiencia en la creación y desarrollo de sistemas integrales de gestión que contemplan las modalidades operativas de las gasolineras. Fue desarrollada con el objetivo de satisfacer totalmente la necesidad de optimizar la operación y administración de las estaciones de servicio incrementando la rentabilidad.`,
      list: [
        {
          text: "Manejo de Inventarios",
        },
        {
          text: "Recepción de Pipas",
        },
        {
          text: "Control Volumétrico",
        },
        {
          text: "Manejo de Flotillas",
        },
        {
          text: "Control de ventas",
        },
        {
          text: "Facturación Electrónica",
        },
        {
          text: "Administración total",
        },
      ],
    };
  },
  action: {
    title: "¡Contactanos ahora!",
    description:
      "Contactanos e indicanos el servicio requerido y nos pondremos en contacto contigo a la brevedad.",
    tagContactanos: "Contactanos",
  },
  paquetes: {
    title: "Paquetes",
    subtitle: "Productos",
    description:
      "Nuestro sistema de diseño modular le permite integrar diferentes componentes para la operación y control de sus estaciones de servicio, que garantizan la correcta funcionalidad y una mejor gestión de su operación.",
    apps: [
      {
        icon: require("../assets/images/icon_sv.svg").default,
        name: "SmartVol control a distancia",
      },
      {
        icon: require("../assets/images/icon_svcv.svg").default,
        name: "SmartVol control volumétrico",
      },
      {
        icon: require("../assets/images/icon_svpos.svg").default,
        name: "SmartVol punto de venta",
      },
      {
        icon: require("../assets/images/icon_svweb.svg").default,
        name: "SmartVol Web",
      },
    ],
    list: [
      {
        title: "Básico",
        description:
          "Simplifica la gestión de tu negocio con las funciones esenciales y cumple con tus obligaciones.",
        simbolCurrency: "$",
        price: "6,500.00",
        detailPrice: "dólares/ Mensual",
        href: "#contact",
        features: [],
      },
      {
        title: "Standar",
        description: "Agrega mas funcionalidades a tu negocio.",
        simbolCurrency: "$",
        price: "6,500.00",
        detailPrice: "dólares/ Mensual",
        href: "#contact",
        features: [
          { text: "Preciadores Electrónicos PWM®, ABLE®" },
          { text: "Control de flotillas y panel de administración" },
          { text: "Gestión de carteras de clientes" },
          { text: "Recepción de vales" },
          { text: "Portal Web Nova Fuel" },
        ],
        apps: [
          {
            icon: require("../assets/images/icon_sv.svg").default,
            name: "SmartVol control a distancia",
          },
          {
            icon: require("../assets/images/icon_svcv.svg").default,
            name: "SmartVol control volumétrico",
          },
          {
            icon: require("../assets/images/icon_svpos.svg").default,
            name: "SmartVol punto de venta",
          },
          {
            icon: require("../assets/images/icon_svweb.svg").default,
            name: "SmartVol Web",
          },
        ],
      },
      {
        title: "Premium",
        description:
          "Agrega mas funcionalidades a tu negocio, a la gestion y la atención de tus clientes.",
        simbolCurrency: "$",
        price: "6,500.00",
        detailPrice: "dólares/ Mensual",
        href: "#contact",
        features: [
          { text: "Administración Web Remota" },
          { text: "Consulta de facturas recibidas de Pemex" },
          { text: "Consulta de Precios de las terminales de Pemex" },
          { text: "Buzón CFDi" },
          { text: "Modulo de Control de Gastos" },
          { text: "Modulo de Bancos" },
          { text: "Pagina Web Personalizada" },
          { text: "Preciadores Electrónicos PWM®, ABLE®" },
          { text: "Control de flotillas y panel de administración" },
          { text: "Gestión de carteras de clientes" },
          { text: "Recepción de vales" },
          { text: "Portal Web Nova Fuel" },
        ],
        apps: [
          {
            icon: require("../assets/images/icon_sv.svg").default,
            name: "SmartVol control a distancia",
          },
          {
            icon: require("../assets/images/icon_svcv.svg").default,
            name: "SmartVol control volumétrico",
          },
          {
            icon: require("../assets/images/icon_svpos.svg").default,
            name: "SmartVol punto de venta",
          },
          {
            icon: require("../assets/images/icon_svweb.svg").default,
            name: "SmartVol Web",
          },
        ],
      },
    ],
  },
  polizas: {
    title: "Polizas",
    description: "Te ofrecemos polizas al alcance y necesidades de tu negocio ",
    list: [
      {
        title: "Standar",
        description: "",
        simbolCurrency: "$",
        price: "6,500.00",
        detailPrice: "dólares/ Mensual",
        buttonText: "SOLICITAR",
        href: "#contact",
        features: [
          { text: "Horario de atención de 8 a 20 hrs." },
          { text: "Tiempo de respuesta 48 horas hábiles." },
          { text: "Generacion y envio de CV" },
        ],
      },
      {
        title: "Premium",
        description: "",
        simbolCurrency: "$",
        price: "6,500.00",
        detailPrice: "dólares/ Mensual",
        buttonText: "SOLICITAR",
        href: "#contact",
        features: [
          { text: "Horario de atención de 24/7" },
          { text: "Sistema de recuperación de desastres 1hrs. de perdida." },
          { text: "Tiempo de respuesta 48 horas hábiles." },
          { text: "Aseguramiento de acceso a la información." },
          { text: "Filtro de contenido en equipos." },
          { text: "Generacion y envio de CV" },
        ],
      },
    ],
  },
  products: {
    title: "Productos",
    subtitle: "",
    description:
      "Nuestro sistema de diseño modular le permite integrar diferentes componentes para la operación y control de sus estaciones de servicio, que garantizan la correcta funcionalidad y una mejor gestión de su operación.",
    list: [
      {
        title: "Sistema de Control a Distancia",
        img: icon_sc,
        description:
          "Software para la unidad central de control que registra la ventas, monitorea el estado de los dispensarios en tiempo real, permite realizar cambios de precio al instante, controlar las cargas mediante un prefijado de litros o importe ; así como la apertura y cierre de turnos.",
        description2:
          "Cumple con los requisitos de Normas Oficiales Mexicanas de metrología.",
      },
      {
        title: "Sistema de control volumétrico",
        img: icon_sc_cv,
        description:
          "Software encargado de la interconexión con el equipo de telemedición de tanques que registra las entradas y salidas de combustible para el monitoreo de inventarios; así como el encargado de la generación y envió de archivos de control volumétrico.",
        description2:
          "Cumple con los requisitos de la legislación mexicana sobre controles volumétricos.",
      },
      {
        title: "SmartVol Punto de Venta",
        img: icon_sc_pos,
        description:
          "Es nuestro software para punto de venta cuyas  funciones principales le permitirá la impresión de tickets, la facturación electrónica CFDi en patio, la posibilidad de vender aceites y/o lubricantes a partir de la lectura del código de barra del producto, la apertura y cierre de turno por parte de sus despachadores desde patio, por dispensarios.",
      },
      {
        title: "SmartVol WEB",
        img: icon_sc_web,
        description:
          "Aplicación Web que se integra a SmartVol y SmartVol CV para su interacción, permitiendo la realización de operaciones y obtención de informes en tiempo real a través de una sencilla interfaz web. Complementada con módulos como la facturación de tickets en ventanilla entre otros.",
      },
    ],
  },
  contact: {
    title: "Contacto",
    subtitle: "Contactanos",
    description: "",
    tagNombre: "Nombre",
    tagResumen: "Resumen",
    tagEstacion: "Nombre estacion",
    tagCargo: "Cargo",
    tagCRE: "Permiso de la CRE",
  },
  footer: {
    tagUseful: "Links Utiles",
    tagOthers: "Otros Links",
    tagCopyright: "Copyright",
    tagRights: "Todos los derechos reservados",
    tagPhone: "Teléfono",
    tagEmail: "E-mail",
    menu: [
      {
        title: "Terminos y condiciones",
        href: "//s3.amazonaws.com/transaccional.files/Novasyn/public/DO-NV-CO-01.pdf",
        newTab: true,
      },
      {
        title: "Aviso de privacidad para clientes",
        href: "//s3.amazonaws.com/transaccional.files/Novasyn/public/DO-NV-AC-01_Aviso_de_privacidad_para_clientes.pdf",
        newTab: true,
      },
      {
        title: "Aviso de privacidad para proveedores",
        href: "//s3.amazonaws.com/transaccional.files/Novasyn/public/DO-NV-CO-02_Aviso_de_privacidad_integral_para_proveedores.pdf",
        newTab: true,
      },
    ],
  },
  floatingBtn: [
    {
      href: "tel:8000553666",
      icon: "bx bx-phone",
    },
    {
      href: "#contact",
      icon: "bx bx-mail-send",
    },
    {
      href: "#home",
      icon: "bx bx-up-arrow",
    },
  ],
};

export default DataIndex;
