import React from "react";
import "../assets/styles/FloatingBtn.css";

function FloatingBtn(props) { 

  // funcion para botones flotantes
  return (
    <a className="shadow" href={props.href}>
      <div className="icon-box">
        <i className={props.icon}></i>
      </div>
    </a>
  );
}

export default FloatingBtn;
