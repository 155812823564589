import React from "react";
import "../assets/styles/Header.css";
import logo from "../assets/images/logo_fondo_primario.svg";
import ItemMenuHeader from "./ItemMenuHeader";
import DataIndex from "./DataIndex";

export default class Header extends React.Component {
  state = {
    className: "navbar navbar-expand-lg fixed-top",
  };

  ref = {
    navbar: React.createRef(),
    btnMobileNav: React.createRef(),
  };

  showMenu = (e) => {
    e.target.classList.toggle("bx-menu");
    e.target.classList.toggle("bx-x");
    this.ref.navbar.current.classList.toggle("navbar-mobile");
  };

  listenDropDown = (e) => {
    const a = Array.from(document.querySelectorAll(".navbar .nav-link"));

    if (a && a.includes(e.target)) {
      if (this.ref.navbar.current.classList.contains("navbar-mobile")) {
        this.ref.btnMobileNav.current.classList.toggle("bx-menu");
        this.ref.btnMobileNav.current.classList.toggle("bx-x");
        this.ref.navbar.current.classList.toggle("navbar-mobile");
      }
    } else {
      console.log("Clicked Outside / Elsewhere");
    }
  };

  listenScrollEvent = (e) => {
    if (window.scrollY > 0) {
      this.setState({
        className: "navbar navbar-expand-lg fixed-top header-scrolled",
      });
    } else {
      this.setState({
        className: "navbar navbar-expand-lg fixed-top",
      });
    }
  };

  componentDidMount() {
    window.addEventListener("scroll", this.listenScrollEvent);
  }

  render() {
    return (
      <header
        id="header"
        className={this.state.className}
        onClick={this.listenDropDown}
      >
        <div className="container d-flex align-items-center justify-content-between">
          <h1 className="logo">
            <a href="/">
              <img src={logo} alt={DataIndex.global.nameCompany} to="/" />
            </a>
          </h1>

          <nav id="navbar" className="navbar" ref={this.ref.navbar}>
            <ul>
              {DataIndex.header.menu.map((actual, index) => {
                return <ItemMenuHeader {...actual} key={index} />;
              })}
            </ul>

            <i
              className="bx bx-menu mobile-nav-toggle"
              ref={this.ref.btnMobileNav}
              onClick={this.showMenu}
            ></i>
          </nav>
        </div>
      </header>
    );
  }
}
